import { privateApi } from '../config';

const URL = 'service-lead/egresso';

export const fetchEgresso = async (params) => (
  privateApi.get(URL, { params })
);

export const templateCsv = async () => privateApi.get(`${URL}/template-csv`);

export const reportEgresso = async () => privateApi.get(`${URL}/report`, { responseType: 'blob' });

export const upload = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return privateApi.post(`${URL}/upload`, formData, config);
};

export const cpf = async (cpfValue = '') => (
  privateApi.get(`${URL}/cpf/${cpfValue}`)
);
