import React from 'react';
import { makeStyles } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useForm } from 'react-hook-form';
import schema from './schema';
import UTextField from '../../../components/UTextField';
import UButton from '../../../components/UButton';
import { useLogin } from '../../../hooks/login';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
}));

function Form() {
  const classes = useStyles();
  const { loading, fetch } = useLogin();
  const { handleSubmit, register, errors } = useForm({
    validationSchema: schema,
  });

  return (
    <form className={classes.formContainer} onSubmit={handleSubmit(fetch)} noValidate>
      <UTextField
        margin="normal"
        required
        fullWidth
        label="Usuário"
        name="username"
        autoComplete="username"
        autoFocus
        inputRef={register}
        error={Boolean(errors.username)}
        helperText={errors.username && errors.username.message}
        disabled={loading}
      />
      <UTextField
        margin="normal"
        required
        fullWidth
        label="Senha"
        name="password"
        type="password"
        autoComplete="current-password"
        inputRef={register}
        error={Boolean(errors.password)}
        helperText={errors.password && errors.password.message}
        disabled={loading}
      />
      <UButton
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        size="large"
        disabled={loading}
        endIcon={<ArrowForwardIcon />}
      >
        Login
      </UButton>
    </form>
  );
}

export default Form;
