import LoginPage from '../pages/Login';
import DashboardPage from '../pages/Dashboard';
import EgressoPage from '../pages/Egresso';

export const Paths = {
  DASHBOARD: '/lead',
  LOGIN: '/lead/login',
  EGRESSO: '/lead/egresso',
};

export const routes = [
  {
    path: Paths.DASHBOARD,
    exact: true,
    component: DashboardPage,
  },
  {
    path: Paths.EGRESSO,
    exact: true,
    component: EgressoPage,
  },
];

export const publicRoutes = [
  {
    path: Paths.LOGIN,
    exact: true,
    component: LoginPage,
  },
];

export const PublicHomeRoute = Paths.LOGIN;
export const PrivateHomeRoute = Paths.DASHBOARD;
export const PrivateEgressoRoute = Paths.EGRESSO;
