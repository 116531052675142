import axios from 'axios';
import { CONFIG, TENANT_ID } from '../constants/config';
import { getTokenFromLocalStorage, removeTokenFromLocalStorage } from './token';
import { LOGOUT_HTTP_STATUS } from '../constants/httpStatus';
import store from '../store';
import { logout } from '../store/ducks/auth';
import MESSAGES from '../constants/messages';

export const publicApi = axios.create({
  baseURL: CONFIG.api,
  headers: { tenantId: TENANT_ID },
});

export const privateApi = axios.create({
  baseURL: CONFIG.api,
  headers: { tenantId: TENANT_ID },
});

privateApi.interceptors.request.use((config) => {
  const token = getTokenFromLocalStorage();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

privateApi.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;
    if (
      response
      && response.status
      && LOGOUT_HTTP_STATUS.includes(response.status)
    ) {
      removeTokenFromLocalStorage();
      store.dispatch(logout());
      return Promise.reject(MESSAGES.sessaoExpirada);
    }
    throw error;
  },
);
