import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as AuthApi from '../../api/auth';
import { authenticationSuccess } from '../../store/ducks/auth';
import { saveTokenLocalStorage } from '../../api/token';

export function useLogin() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [data, setData] = useState(null);

  async function fetch({ username, password }) {
    setLoading(true);
    try {
      const { data: loginResponse } = await AuthApi.requestLogin({ username, password });
      setData(loginResponse);
      saveTokenLocalStorage(loginResponse.access_token);
      dispatch(authenticationSuccess({
        userId: 1,
        username,
      }));
    } catch (err) {
      setError(true);
      setErrorMessage(err && err.message);
    } finally {
      setLoading(false);
    }
  }

  return {
    data,
    error,
    errorMessage,
    loading,
    fetch,
  };
}
