import React from 'react';
import ReactDOM from 'react-dom';
import { WebSocketProvider } from './WebSocketContext';
import './i18n/locale';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <WebSocketProvider>
      <App />
    </WebSocketProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
