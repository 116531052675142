import React, { useState } from 'react';
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Hidden,
  makeStyles,
} from '@material-ui/core';
import {
  Search as SearchIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';
import UPaper from '../../../components/UPaper';
import UTextField from '../../../components/UTextField';
import UButton from '../../../components/UButton';

const useFormStyles = makeStyles((theme) => ({
  panelSummary: {
    margin: 0,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  panelDetails: {
    padding: theme.spacing(1),
  },
  panelHeading: {
    display: 'flex',
    alignItems: 'center',
    flexBasis: '32%',
    flexShrink: 0,
  },
  panelSubHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    alignSelf: 'center',
  },
  panelSearchIcon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(3),
  },
  form: {
    width: '100%',
  },
}));

// eslint-disable-next-line react/prop-types
function Filter({ onSubmit }) {
  const classes = useFormStyles();
  const [expanded, setExpanded] = useState(false);
  const [cpf, setCpf] = useState('');
  const [name, setName] = useState('');
  const [ciclo, setCiclo] = useState('');
  const [curso, setCurso] = useState('');
  const [origemCogna, setOrigemCogna] = useState('');

  const handlePanelExpand = () => setExpanded(!expanded);

  const handleSendFilters = () => {
    onSubmit({
      cpf,
      name,
      ciclo,
      curso,
      origemCogna,
    });
    setExpanded(false);
  };

  const handleClearFields = () => {
    setCpf('');
    setName('');
    setCiclo('');
    setCurso('');
    setOrigemCogna('');

    setExpanded(false);
  };

  return (
    <Accordion
      component={UPaper}
      insetSize="1x"
      expanded={expanded}
      onChange={handlePanelExpand}
    >
      <AccordionSummary
        classes={{ content: classes.panelSummary }}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography
          className={classes.panelHeading}
          variant="h6"
          component="h2"
        >
          <SearchIcon className={classes.panelSearchIcon} />
          Filtrar
        </Typography>
        {!expanded && (
          <Hidden
            className={classes.panelSubHeading}
            smDown
            implementation="css"
          >
            <Typography>Clique para filtrar os resultados</Typography>
          </Hidden>
        )}
      </AccordionSummary>
      <AccordionDetails className={classes.panelDetails}>
        <form className={classes.form}>
          <Grid container direction="row">
            <Grid item md={4} xs={12}>
              <UTextField
                label="CPF"
                name="cpfField"
                fullWidth
                onChange={(e) => setCpf(e.target.value)}
                value={cpf}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <UTextField
                label="Nome"
                name="nameField"
                fullWidth
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <UTextField
                label="Ciclo"
                name="cicloField"
                fullWidth
                onChange={(e) => setCiclo(e.target.value)}
                value={ciclo}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <UTextField
                label="Curso"
                name="cursoField"
                fullWidth
                onChange={(e) => setCurso(e.target.value)}
                value={curso}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <UTextField
                label="Origem"
                name="origemField"
                fullWidth
                onChange={(e) => setOrigemCogna(e.target.value)}
                value={origemCogna}
              />
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item md={2}>
              <UButton
                type="button"
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSendFilters}
              >
                Buscar
              </UButton>
            </Grid>
            <Grid item md={2}>
              <UButton
                type="button"
                variant="outlined"
                color="secondary"
                fullWidth
                onClick={handleClearFields}
              >
                Limpar
              </UButton>
            </Grid>
          </Grid>
        </form>
      </AccordionDetails>
    </Accordion>
  );
}

export default Filter;
