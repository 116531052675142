import { createMuiTheme } from '@material-ui/core/styles';

const palette = {
  primary: { main: '#9960E3', contrastText: '#ffffff' },
  secondary: { main: '#8948DF' },
};

export const drawerWidth = 240;

export default createMuiTheme({ palette });
