import { publicApi } from '../config';

const loginEndpoint = '/login';

export function requestLogin({ username, password }) {
  return publicApi.post(loginEndpoint, {
    username,
    password,
  });
}
