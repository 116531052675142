import React, { useEffect, useState } from 'react';
import {
  makeStyles, Grid, Drawer, Snackbar, Backdrop, CircularProgress,
} from '@material-ui/core';
import moment from 'moment';
import { Alert, AlertTitle } from '@material-ui/lab';
import PrintIcon from '@material-ui/icons/Print';
import CloudUpload from '@material-ui/icons/CloudUpload';
import PostAddIcon from '@material-ui/icons/PostAdd';
import PageHeader from '../../components/PageHeader';
import List from './List';
import UFab from '../../components/UFab';
import Filter from './Filter';
import Form from './Form';
import * as api from '../../api/Egresso/api';

const useFormStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function Egresso() {
  const classes = useFormStyles();
  const [formOpen, setFormOpen] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filteredList, setFilteredList] = useState([]);

  const toggleDrawer = (value) => {
    setFormOpen(value);
  };

  const onReport = async () => {
    try {
      setLoading(true);
      const { data } = await api.reportEgresso();
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `egressos-${moment().format('HHmmss')}.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      setError('Erro ao fazer download do relatório');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const downloadTemplateCsv = async () => {
    try {
      setLoading(true);
      const { data } = await api.templateCsv();
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'template-csv-participante.csv');
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      setError('Erro ao fazer download do template');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const callbackForm = (value) => {
    if (value) {
      setFormOpen(false);
      setSuccess(true);
    }
  };

  const actions = [
    {
      icon: <PrintIcon />,
      name: 'Exportar relatório CSV',
      color: 'primary',
      onClick: onReport,
    },
    {
      icon: <CloudUpload />,
      name: 'Upload CSV',
      color: 'primary',
      onClick: toggleDrawer,
    },
    {
      icon: <PostAddIcon />,
      name: 'Download template CSV',
      color: 'primary',
      onClick: downloadTemplateCsv,
    },
  ];

  useEffect(() => {

  }, [filteredList]);

  const handleFormFilters = (data) => {
    // eslint-disable-next-line no-shadow
    setFilteredList(data);
  };

  return (
    <div>
      <PageHeader title="Listagem de Leads" subheading="Consulta" />
      <Grid container justify="center" alignItems="center">
        <Grid item sm={12} md={12} lg={8} style={{ maxWidth: '100%' }}>
          <Filter onSubmit={handleFormFilters} />
          <List filteredList={filteredList} />
        </Grid>
        <UFab actions={actions} />
        <Drawer anchor="right" open={formOpen} onClose={() => toggleDrawer(false)}>
          <Form callbackForm={callbackForm} />
        </Drawer>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={success}
          autoHideDuration={5000}
          onClose={() => setSuccess(false)}
        >
          <Alert variant="outlined" severity="info">
            <AlertTitle>Importação de egressos</AlertTitle>
            Iniciada em segundo plano!
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={error}
          autoHideDuration={5000}
          onClose={() => setError(null)}
        >
          <Alert variant="outlined" severity="error">
            <AlertTitle>Erro</AlertTitle>
            {error}
          </Alert>
        </Snackbar>
      </Grid>
      <Backdrop className={classes.backdrop} open={loading} onClick={() => setLoading(false)}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default Egresso;
