import React from 'react';

import {
  makeStyles,
  TableRow,
  TableCell,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const useRowStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  card: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

// eslint-disable-next-line react/prop-types
function Row({ row }) {
  const classes = useRowStyles();
  return (
    <>
      <TableRow key={row.name} className={classes.root}>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell>{row.cpf}</TableCell>
        <TableCell>{row.details.ciclo}</TableCell>
        <TableCell>{row.details.curso}</TableCell>
        <TableCell>{row.details.origemCogna}</TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
    cpf: PropTypes.string.isRequired,
    details: {
      ciclo: PropTypes.string.isRequired,
      curso: PropTypes.string.isRequired,
      origemCogna: PropTypes.string.isRequired,
    },
  }).isRequired,
};

export default Row;
